import {
  article,
  community,
  country,
  repost,
} from "../../js/path";
import moment from "moment";
require('dotenv').config()
// import axios from "axios";
import _ from "lodash";

export default {
  data() {
    return {
      status: false,
      pageNo: null,
      article_id: null,
      table_header: [],
      fields: [{
          key: "id",
          label: "ID",
          sortable: true,
        },
        {
          key: 'title'
        },
        {
          key: 'header'
        },
        {
          key: "journal",
        },
        {
          key: "article_timestamp",
          label: "Date",
          formatter: value => {
            return moment(String(value)).format('ll')
          },
          class: 'timestamp'
        },
        {
          key: "is_active",
          label: "Status",
        },
        {
          key: 'article_schedule',
          label: 'Available for Articles',
          class: 'timestamp'
        },
        {
          key: "repost",
        },
        {
          key: "edit",
        },
        {
          key: "delete",
        },
      ],
      community: [],
      oldForm: {},
      form: {
        view_multiplication_factor: 0,
        translation: {
          indonesia: {
            title: '',
            header: '',
            link: '',
            small_description: '',
            journal: '',
            meta_title: '',
            meta_keywords: '',
            meta_desc: '',
            button_text: '',
          }
        },
        community_selected: [],
        card_name: "",
        article_timestamp: null,
        title: "",
        header: "",
        journal: "",
        small_description: "",
        link: "",
        meta_title: "",
        meta_desc: "",
        meta_keywords: "",
        content: "",
        notification_schedule: false,
        article_schedule: 0,
        button_text: '',
        url_link: "",
        country: [],
      },
      placeholder: null,
      currentPage: 1,
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      activeTab: "all",
      tableData: [],
      key: 0,
      params: "",
      card_image: "",
      card_image_url: "",
      image_name_indonesia_url: '',
      image_name_indonesia: '',
      logo_image:"",
      logo_image_url:"",
      edit: {
        card_image_url: "",
        image_name_indonesia_url: null,
        logo_image_url:""
      },
      allCountry: [],
      showRepostModal: false,
      modalItem: null
    };
  },
  computed: {
    allCountryWithAll() {
       return [{ id: 'all', name: 'All' }, ...this.allCountry];
    }
  },
  methods: {
    openRepostModal(item) {
      this.modalItem = item.id;
      this.showRepostModal = true;
   },
   closeRepostModal() {
      this.showRepostModal = false;
   },
   async handleRepostSubmit() {
      this.$store.commit("loader/updateStatus", true);
      try {
        let dataAppend = new FormData();
        dataAppend.append("content_type", "article");
        dataAppend.append("content_id", this.modalItem);
        let url = repost;
        const data = await this.postRequest(url, dataAppend);
        if (data.status) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: data.response.message ? data.response.message : "Updated successfully",
            });
        } else {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "error",
              title: "Something went wrong",
            });
        }
        this.$nextTick(() => {
            this.$bvModal.hide("bvRepostModal");
        });
      } catch (err) {
        console.log(err);
        this.$nextTick(() => {
            this.$bvModal.hide("bvRepostModal");
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    slugify() {
      this.article_header = this.form.header;
      if (this.article_header) {
        const slug_generate = this.article_header
          .toString()
          .trim()
          .toLowerCase()
          .replace(/\s+/g, "-")
          .replace(/[^\w-]+/g, "")
          .replace(/--+/g, "-")
          .replace(/^-+/, "")
          .replace(/-+$/, "");
        this.form.url_link = slug_generate;
      } else {
        this.form.url_link = null;
      }
    },
    summarizeArticle() {
      if(this.form.link != null && this.form.link != "") {
        this.$store.commit("loader/updateStatus", true);
        const options = {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            'X-RapidAPI-Key': process.env.VUE_APP_RAPID_API_KEY,
            'X-RapidAPI-Host': 'tldrthis.p.rapidapi.com'
          },
          body: JSON.stringify({"url": this.form.link,"min_length":100,"max_length":300,"is_detailed":false})
        };
        let _this = this;
        fetch('https://tldrthis.p.rapidapi.com/v1/model/abstractive/summarize-url/', options)
          .then(response => response.json())
          .then(function(response) {
            const responseData = response;
            _this.$store.commit("loader/updateStatus", false);
            const summary = responseData.summary[0].replace(/['"]+/g, '');
            _this.form.small_description = summary;
          })
          .catch(err => {
            if(err) {
              this.form.small_description = "";
              this.$store.commit("toast/updateStatus", {
                status: true,
                icon: "error",
                title: "Failed to summarize the link"
              })
            }
            _this.$store.commit("loader/updateStatus", false);
          })
      }
    },
    rowClass(item) {
      if (item.duplicate_ids.length > 0) {
        return 'table-danger'
      } 
      else {
        return 'table-light'
      }
    },
    searchFor() {
      if (this.filter.length > 1) {
        this.fetchData(this.activeTab); 
      } else if (this.filter.length == 0) {
        this.fetchData(this.activeTab);
      }
    },
    filterPage() {
      if (this.pageNo.length > 0) {
        this.params = `&page=${this.pageNo}`;
        this.fetchData(this.activeTab);
        this.currentPage = this.pageNo;
      }
    },
    search(event) {
      if (this.filter.length > 1) {
        if (event.keyCode == 13) {
          this.fetchData(this.activeTab);
        }
      } else if (this.filter.length == 0) this.fetchData(this.activeTab);
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      // this.currentPage = 1;
    },
    async fetchData(txt) {
      let url = null;
      if (txt == "active") {
        this.activeTab = txt;
        if (this.filter != null && this.filter != "") {
          url =
            article.articleUrl + "?filter=" + txt + "&search=" + this.filter;
        } else {
          url = article.articleUrl + "?filter=" + txt;
        }
      } else if (txt == "trash") {
        this.activeTab = txt;
        if (this.filter != null && this.filter != "") {
          url =
            article.articleUrl + "?filter=" + txt + "&search=" + this.filter;
        } else {
          url = article.articleUrl + "?filter=" + txt;
        }
      } else if (txt == "all") {
        this.activeTab = txt;
        if (this.filter != null && this.filter != "") {
          url =
            article.articleUrl + "?filter=" + txt + "&search=" + this.filter;
        } else {
          url = article.articleUrl + "?filter=" + txt;
        }
      }
      url += this.params;
      this.getUrl();
      this.$store.commit("loader/updateStatus", true);
      try {
        const data = await this.getRequest(url);
        if (data.status) {
          const responseData = data.response;
          this.tableData = responseData;
        }
      } catch (err) {
        console.log(err);
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
      this.key += 1;
    },
    async fetchArticle(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = article.articleUrl + "/" + id;
        const data = await this.getRequest(url);
        if (data.status) {
          const responseData = data.response;

          if (responseData.card_name != "" && responseData.card_name != null) {
            this.form.card_name = responseData.card_name;
          }

          if (responseData.title != "" && responseData.title != null) {
            this.form.title = responseData.title;
          }

          if (responseData.header != "" && responseData.header != null) {
            this.form.header = responseData.header;
          }

          if (responseData.journal != "" && responseData.journal != null) {
            this.form.journal = responseData.journal;
          }

          if (responseData.small_description != "" && responseData.small_description != null) {
            this.form.small_description = responseData.small_description;
          }

          if (responseData.link != "" && responseData.link != null) {
            this.form.link = responseData.link;
          }

          if (responseData.meta_title != "" && responseData.meta_title != null) {
            this.form.meta_title = responseData.meta_title;
          }

          if (responseData.meta_desc != "" && responseData.meta_desc != null) {
            this.form.meta_desc = responseData.meta_desc;
          }

          if (responseData.meta_keywords != "" && responseData.meta_keywords != null) {
            this.form.meta_keywords = responseData.meta_keywords;
          }

          if (responseData.content != "" && responseData.content != null) {
            this.form.content = responseData.content;
          }

          if (responseData.button_text != "" && responseData.button_text != null) {
            this.form.button_text = responseData.button_text;
          }

          if (responseData.url_link != "" && responseData.url_link != null) {
            this.form.url_link = responseData.url_link;
          }

          if (responseData.article_schedule) {
            this.form.article_schedule = true;
          } else {
            this.form.article_schedule = false;
          }

          if (responseData.country != null && responseData.country != "") {
            responseData.country.map((item) => {
              this.fetchSingleCountry(item);
            })
          }

          this.edit.card_image_url = responseData.card_image;
          this.card_image = responseData.card_image;

          if(responseData.logo_image != null && responseData.logo_image != ""){
            this.edit.logo_image_url = responseData.logo_image;
            this.logo_image= responseData.logo_image;
          }

          this.form.article_timestamp = moment(responseData.article_timestamp).format("YYYY-MM-DDTHH:mm");

          if (responseData.translation != "" && responseData.translation != null) {
            this.form.translation = responseData.translation;
            this.edit.image_name_indonesia_url = responseData.translation.indonesia.image ? responseData.translation.indonesia.image : '';
          }

          this.article_id = responseData.id;
          this.small_desc = responseData.small_description;
          if (responseData.view_multiplication_factor != "" && responseData.view_multiplication_factor != null) {
            if (responseData.view_multiplication_factor == 0) {
              this.form.view_multiplication_factor = 1
            } else {
              this.form.view_multiplication_factor =
              responseData.view_multiplication_factor;
            }
          }

          if (responseData.community != null && responseData.community != "") {
            this.form.community_selected = responseData.community.map((c) => {
              return {
                id: c.community_id,
                title: c.name,
              };
            });
          }
          this.oldForm = _.clone(this.form);
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async restoreArticle(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let index = this.tableData.data.findIndex((e) => e.id === id);
        const url = article.articleUrl + "/" + id;
        const data = await this.postRequest(url, {
          _method: "DELETE",
        });
        if (data.status) {
          this.tableData.data.splice(index, 1);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    getUrl() {
      var url = new URL(window.location);
      if (this.activeTab == null || this.activeTab == "") {
        this.activeTab = "all";
      }
      (url.searchParams.has('filter') ? url.searchParams.set('filter', this.activeTab) : url.searchParams.append('filter', this.activeTab));
      if (this.filter != null && this.filter != "") {
        (url.searchParams.has('search') ? url.searchParams.set('search', this.filter) : url.searchParams.append('search', this.filter));
      } else {
        url.searchParams.delete('search');
      }
      if (this.currentPage != null && this.currentPage != "") {
        (url.searchParams.has('page') ? url.searchParams.set('page', this.currentPage) : url.searchParams.append('page', this.currentPage));
      }
      // else {
      //   url.searchParams.set('page', 1);
      // }
      url.search = url.searchParams;
      url = url.toString();
      history.pushState({}, null, url);
    },
    async updateStatus(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let index = this.tableData.data.findIndex((e) => e.id === id);
        const url = article.statusUpdate;
        const data = await this.postRequest(url, {
          is_active: !this.tableData.data[index].is_active,
          id: id,
        });
        if (data.status) {
          this.tableData.data[index].is_active = !this.tableData.data[index].is_active;
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
        this.key += 1;
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async deleteArticle(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let index = this.tableData.data.findIndex((e) => e.id === id);
        const url = article.articleUrl + "/" + id;
        const data = await this.postRequest(url, {
          _method: "DELETE",
        });
        if (data.status) {
          this.tableData.data.splice(index, 1);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async fetchCommunity(query) {
      const url = community.fetchAllActiveCommunity;
      const data = await this.getRequest(url + '&search=' + query);
      if (data.status) {
        this.community = data.response.data;
      }
    },
    async fetchAllCommunity() {
      const url = community.fetchAllActiveCommunity;
      const data = await this.getRequest(url);
      if (data.status) {
        this.form.community_selected = data.response.data;
      }
    },
    readFile(e, txt) {
      if (txt == "card_image") {
        this.card_image = e.target.files[0];
        if (this.card_image.size > 2 * 1000 * 1000) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Uploaded File is More than 2MB"
          });
          this.$refs.card_image.reset();
          this.card_image_url = '';
          this.edit.card_image_url = '';
          this.card_image = '';
          return false;
        } else {
          this.card_image_url = URL.createObjectURL(this.card_image);
          this.edit.card_image_url = "";
        }
        return true;
      } else if (txt == 'image_name_indonesia') {
        this.image_name_indonesia = e.target.files[0];
        if (this.image_name_indonesia.size > 2 * 1000 * 1000) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Uploaded File is More than 2MB"
          });
          this.$refs.image_name_indonesia.reset();
          this.image_name_indonesia_url = '';
          this.edit.image_name_indonesia_url = '';
          this.image_name_indonesia = '';
          return false;
        } else {
          this.image_name_indonesia_url = URL.createObjectURL(this.image_name_indonesia);
          this.edit.image_name_indonesia_url = '';
        }
        return true;
      } else if (txt == "logo_image") {
        this.logo_image = e.target.files[0];
        if (this.logo_image.size > 2 * 1000 * 1000) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Uploaded File is More than 2MB",
          });
          this.$refs.logo_image.reset();
          this.logo_image_url = "";
          this.edit.logo_image_url = "";
          this.logo_image = "";
          return false;
        } else {
          this.logo_image_url = URL.createObjectURL(this.logo_image);
          this.edit.logo_image_url = "";
        }
        return true;
      }
    },
    async submitData() {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = article.articleUrl;
        if (this.$route.name == 'edit-article') {
          url = article.articleUrl + '/' + this.article_id;
        }
        let dataAppend = new FormData();
        if (this.card_image) {
          dataAppend.append("card_image", this.card_image);
        }
        if (this.image_name_indonesia) {
          dataAppend.append("image_name_indonesia", this.image_name_indonesia);
        }
        if (this.logo_image) {
          dataAppend.append("logo_image", this.logo_image);
        }
        dataAppend.append("translation", JSON.stringify(this.form.translation));
        for (var key in this.form) {
          if (key != "translation" && key != 'community_selected' && key != 'article_schedule' && key != 'country') {
            dataAppend.append(key, this.form[key]);
          }
        }
        const community_selected = this.form.community_selected.map(
          (key) => key.id
        );
        community_selected.forEach((c) =>
          dataAppend.append("community_selected[]", c)
        );
        const country = this.form.country.map((key) => key.name);
        country.forEach(l => dataAppend.append("country[]", l));
        if (this.form.article_schedule) {
          dataAppend.append("article_schedule", 1);
        } else {
          dataAppend.append("article_schedule", 0);
        }
        if (this.$route.name == "edit-article") {
          dataAppend.append("_method", "put");
        }
        const data = await this.postRequest(url, dataAppend);
        if (data.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
          this.$router.push("/article");
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async fetchExcel() {
      this.$store.commit("loader/updateStatus", true);
      try {
        const url = article.importFile;
        let dataAppend = new FormData();
        dataAppend.append("excel", this.$refs.file.files[0]);
        const data = await this.postRequest(url, dataAppend);
        if (data.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: "File added successfully",
          });
          this.$refs.file.reset();
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    tabActive() {
      if (this.activeTab == "trash") {
        if (this.table_header.includes("delete")) {
          let index = this.fields.findIndex((item) => item.key == "delete");
          this.fields[index] = {
            key: "restore",
          };
          let table_index = this.table_header.findIndex((item) => item == "delete");
          this.table_header[table_index] = "restore";

        } else {
          this.table_header.push("restore");
          this.fields.push({
            key: "restore",
          });
        }

        if (!this.can("restore-article")) {
          let index = this.fields.findIndex((item) => item.key == "restore");
          if (index !== -1) {
            this.fields.splice(index, 1);
            let table_index = this.table_header.findIndex(
              (item) => item == "restore"
            );
            this.table_header.splice(table_index, 1);
          }
        }

        let index = this.fields.findIndex((item) => item.key == "edit");
        if (index !== -1) {
          this.fields.splice(index, 1);
          let table_index = this.table_header.findIndex(
            (item) => item == "edit"
          );
          this.table_header.splice(table_index, 1);
        }
      } else {
        if (this.table_header.includes("restore")) {
          if (!this.can("edit-article")) {
            this.fields[9] = {
              key: "delete",
            };
          } else {
            this.fields[10] = {
              key: "delete",
            };
          }

          let table_index = this.table_header.findIndex((item) => item == "restore");
          this.table_header[table_index] = "delete";
        }

        if (!this.can("edit-article")) {
          let index = this.fields.findIndex((item) => item.key == "edit");
          if (index !== -1) {
            this.fields.splice(index, 1);
            let table_index = this.table_header.findIndex(
              (item) => item == "edit"
            );
            this.table_header.splice(table_index, 1);
          }
        } else {
          if (!this.table_header.includes("edit")) {
            this.table_header.push("edit");
            this.fields[9] = {
              key: "edit",
            };
          }
        }

        if (!this.can("delete-article")) {
          let index = this.fields.findIndex((item) => item.key == "delete");
          if (index !== -1) {
            this.fields.splice(index, 1);
            let table_index = this.table_header.findIndex(
              (item) => item == "delete"
            );
            this.table_header.splice(table_index, 1);
          }
        } else {
          if (!this.table_header.includes("delete")) {
            this.table_header.push("delete");
            this.fields[10] = {
              key: "delete",
            };
          }
        }
      }
    },
    async fetchCountry(query) {
      const url = country.countryUrl;
      const data = await this.getRequest(url + '?search=' + query);
      if (data.status) {
        this.allCountry = data.response.data;
      }
    },
    async fetchSingleCountry(name) {
      const url = country.countryUrl;
      if(name == 'All'){
        this.form.country = [{ id: 'all', name: 'All' }];
      }else{
        const data = await this.getRequest(url + '/' + name + '?country_name=1');
        if (data.status) {
          this.form.country.push(data.response);
        }
      }
    },
  },
  watch: {
    status(v) {
      if (v == 1) {
        this.fetchAllCommunity();
      } else {
        if (this.$route.name == "add-article") {
          this.form.community_selected = [];
        } else if (this.$route.name == "edit-article") {
          this.form.community_selected = this.oldForm.community_selected;
        }
      }
    },
    "form.article_schedule"(v) {
      if (v == 1) {
        this.form.article_schedule = true;
      } else {
        this.form.article_schedule = false;
      }
    },
    "form.link"(v) {
      if(v) {
        this.isSummarize = true;
      } else {
        this.isSummarize = false;
      }
    },
    currentPage: {
      handler: function (value) {
        this.params = `&page=${value}`;
        this.fetchData(this.activeTab);
        this.pageNo = this.currentPage;
      },
    },
    activeTab(v) {
      if (v) this.tabActive();
    },
    'form.country' : {
      handler(newValue) {
        if (newValue.includes('all')) {
          this.form.country = ['all'];
        } else if (newValue.includes('all') && newValue.length > 1) {
          this.form.country = newValue.filter(value => value !== 'all');
        }
      },
      deep: true
    }
  },
  mounted() {
    if (
      this.$route.name == "add-article" ||
      this.$route.name == "edit-article"
    ) {
      // this.fetchCommunity();
      if (this.$route.name == "edit-article") {
        this.fetchArticle(this.$route.params.id);
      }
    } else {
      this.activeTab = "all";
      if (this.$route.query.filter) {
        this.activeTab = this.$route.query.filter;
      }
      if (this.$route.query.search) {
        this.filter = this.$route.query.search;
      }
      if (this.$route.query.page && this.$route.query.page != 1) {
        this.currentPage = parseInt(this.$route.query.page);
      } else {
        this.fetchData(this.activeTab);
      }
    }
    this.fields.map((item) => {
      this.table_header.push(item.key);
    });
    this.tabActive();
  },
};