<template>
  <Layout>
    <b-tabs>
      <b-tab title="English">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <form-wizard @on-complete="submitData" color="#556ee6" enctype="multipart/form-data" ref="article"
                  :finishButtonText="this.$route.name == 'add-article' ? 'Save Data' : 'Update Data'"
                  back-button-text="Go Back!" next-button-text="Go Next!">
                  <tab-content title="Article Details" icon="mdi mdi-account-details"
                    :before-change="() => validateFormOne()">
                    <div class="row">
                      <b-form-group class="col-12">
                        <label for="article_title">Article Title <span style="color: red">*</span></label>
                        <b-form-input id="article_title" v-model="form.title"
                          placeholder="Enter title" rows="1"
                          :class="{ 'is-invalid': submitted && $v.form.title.$invalid }" :maxlength="500">
                        </b-form-input>
                        <div v-if="submitted && !$v.form.title.required" class="invalid-feedback">Title is required.
                        </div>
                        <div class="text-right">
                          <p v-if="form.title" class="badge position-absolute" style="top: 4px; right: 13px"
                            :class="{
                              'badge-success': form.title.length !== 500,
                              'badge-danger': form.title.length === 500
                            }">
                            You typed
                            {{ form.title.length }} out of 500 chars.
                          </p>
                        </div>
                      </b-form-group>
                      <b-form-group class="col-6">
                        <label for="article_header">Article Header <span style="color: red">*</span></label>
                        <b-form-textarea id="article_header" v-model="form.header" @keyup.prevent="slugify"
                          placeholder="[Eg: EMERALD study: A brief report of kidney and inflammatory outcomes]" rows="3"
                          :class="{ 'is-invalid': submitted && $v.form.header.$invalid }" :maxlength="120">
                        </b-form-textarea>
                        <div v-if="submitted && !$v.form.header.required" class="invalid-feedback">Header is required.
                        </div>
                        <div class="text-right">
                          <p v-if="form.header" class="badge position-absolute" style="top: 4px; right: 13px"
                            :class="{
                              'badge-success': form.header.length !== 120,
                              'badge-danger': form.header.length === 120
                            }">
                            You typed
                            {{ form.header.length }} out of 120 chars.
                          </p>
                        </div>
                      </b-form-group>
                      <b-form-group class="col-6" label="Article Link" label-for="article_link">
                        <b-form-textarea id="article_link" v-model="form.link" @input="isArticle"
                          placeholder="[Eg: https://www.annfammed.org/content/early/2020/12/15/afm.2591]" rows="3">
                        </b-form-textarea>
                      </b-form-group>
                      <b-form-group class="col-12">
                        <label for="article_url_link">URL Link </label>
                        <b-form-input id="article_url_link" v-model="form.url_link" placeholder="Enter URL Link"></b-form-input>
                      </b-form-group>
                      <b-form-group class="col-12" id="article_desc">
                        <div class="d-flex align-items-center justify-content-between">
                          <label for="small_desc">Small Description of the Article <span
                            style="color: red">*</span></label>
                          <div class="d-flex align-items-center">
                            <button class="badge badge-info btn mr-2" v-if="isSummarize" @click="summarizeArticle">Summarize Article</button>
                            <button class="badge btn" v-if="form.small_description"
                              :class="{
                                'badge-success': form.small_description.length !== 900,
                                'badge-danger': form.small_description.length === 900
                              }">
                              You typed
                              {{ form.small_description.length }} out of 900 chars.
                            </button>
                          </div>
                        </div>
                        <b-form-textarea id="small_desc" v-model="form.small_description"
                          placeholder="Enter Small Description" :maxlength="900"
                          :class="{ 'is-invalid': submitted && $v.form.small_description.$invalid }" rows="5">
                        </b-form-textarea>
                        <div v-if="submitted && !$v.form.small_description.required" class="invalid-feedback">Small
                          Description is required.</div>
                      </b-form-group>
                      <b-form-group class="col-6">
                        <label for="article_journal">Journal </label>
                        <b-form-input id="article_journal" v-model="form.journal" :maxlength="250"
                          placeholder="[Eg: European Journal of Heart Failure]">
                        </b-form-input>
                        <div class="text-right">
                          <p v-if="form.journal" class="badge position-absolute" style="top: 4px; right: 13px" :class="{
                            'badge-success': form.journal.length !== 250,
                            'badge-danger': form.journal.length === 250
                          }">
                            You typed
                            {{ form.journal.length }} out of 250 chars.
                          </p>
                        </div>
                      </b-form-group>
                      <b-form-group class="col-6" label="Button Text" label-for="article_button_text">
                        <b-form-input id="article_button_text" v-model="form.button_text"
                          placeholder="Enter Button Text">
                        </b-form-input>
                      </b-form-group>
                      <b-form-group class="col-6">
                        <label for="article_thumbnail_img">Thumbnail Image [Upload Max File Size : 2MB] <span
                            style="color: red">*</span></label>
                        <b-form-file id="article_thumbnail_img" accept="image/*"
                          placeholder="Choose a file or drop it here..." @change="readFile($event, 'card_image')"
                          ref="card_image" :class="{ 'is-invalid': submitted && $v.card_image.$invalid }">
                        </b-form-file>
                        <template v-if="$route.name == 'edit-article' && edit.card_image_url">
                          <img :src="edit.card_image_url" width="128px" height="128px" style="object-fit: contain" />
                        </template>
                        <template v-if="card_image_url">
                          <img :src="card_image_url" width="128px" height="128px" ref="card_image_url"
                            style="object-fit: contain; margin-top: 5px" />
                        </template>
                        <div v-if="submitted && !$v.card_image.required" class="invalid-feedback">Image is required.
                        </div>
                      </b-form-group>
                      <b-form-group class="col-6">
                        <label for="article_logo">Logo Image [Upload Max File Size : 2MB] </label>
                        <b-form-file id="article_logo" accept="image/*"
                          placeholder="Choose a file or drop it here..." @change="readFile($event, 'logo_image')"
                          ref="logo_image" >
                        </b-form-file>
                        <template v-if="$route.name == 'edit-article' && edit.logo_image_url">
                          <img :src="edit.logo_image_url" width="128px" height="128px" style="object-fit: contain" />
                        </template>
                        <template v-if="logo_image_url">
                          <img :src="logo_image_url" width="128px" height="128px" ref="logo_image_url"
                            style="object-fit: contain; margin-top: 5px" />
                        </template>
                      </b-form-group>
                      <b-form-group label="Country" label-for="input-multi" class="col-6">
                           <multiselect @search-change="fetchCountry" id="input-multi" v-model="form.country"
                              :options="allCountryWithAll" :multiple="true" track-by="id" placeholder="Type here to search"
                              label="name">
                              <span slot="noOptions">Type here to search</span>
                           </multiselect>
                        </b-form-group>
                    </div>
                  </tab-content>
                  <tab-content title="Add Tags" icon="fa fa-tag" :before-change="() => validateFormTwo()">
                    <div class="row">
                      <b-form-group class="col-6">
                        <label for="date">Article Scheduled Date <span style="color: red">*</span></label>
                        <b-form-input id="date" v-model="form.article_timestamp" type="datetime-local"
                          :min="disabledDates()"
                          :class="{ 'is-invalid': submitted2 && $v.form.article_timestamp.$invalid }">
                        </b-form-input>
                        <div v-if="submitted2 && !$v.form.article_timestamp.required" class="invalid-feedback">Article
                          Scheduled Date is required.</div>
                      </b-form-group>
                      <b-form-group class="col-6">
                        <div class="d-flex justify-content-between align-items-center mb-2">
                          <label for="comm" class="mb-0">Community <span style="color: red"> *</span></label>
                          <b-form-checkbox v-model="status" button button-variant="info" size="sm">
                            <template v-if="status">Unselect All</template>
                            <template v-else>Select All</template>
                          </b-form-checkbox>
                        </div>
                        <multiselect @search-change="fetchCommunity" id="comm" v-model="form.community_selected"
                          :options="community" :multiple="true" track-by="id" label="title"
                          placeholder="Type here to search" :class="{
                            'is-invalid':
                              submitted2 && $v.form.community_selected.$invalid,
                          }">
                          <span slot="noOptions"> Type here to search </span>
                        </multiselect>
                        <div v-if="submitted2 && !$v.form.community_selected.required" class="invalid-feedback">
                          Community is required.
                        </div>
                      </b-form-group>
                      <b-form-group label="View Multiplication Factor" label-for="view_multi_factor"
                        class="col-4">
                        <b-form-input id="view_multi_factor" type="number"
                            placeholder="Enter View Multiplication Factor"
                            v-model="form.view_multiplication_factor">
                        </b-form-input>
                      </b-form-group>
                      <b-form-group class="col-4" label="Meta Title" label-for="article_meta_title">
                        <b-form-input id="article_meta_title" v-model="form.meta_title" placeholder="Enter Meta Title">
                        </b-form-input>
                      </b-form-group>
                      <b-form-group class="col-4" label="Meta Keywords" label-for="article_meta_key">
                        <b-form-input id="article_meta_key" v-model="form.meta_keywords"
                          placeholder="Enter Meta Keywords">
                        </b-form-input>
                      </b-form-group>
                    </div>
                    <b-form-group label="Meta Description" label-for="article_meta_desc">
                      <b-form-textarea id="textarea" v-model="form.meta_desc" placeholder="Enter Meta Description"
                        rows="3"></b-form-textarea>
                    </b-form-group>
                    <b-form-group>
                      <div style="display: flex; gap: 10px">
                        <b-form-checkbox v-model="form.article_schedule">Available for Article</b-form-checkbox>
                        <b-form-checkbox v-model="form.notification_schedule">Scheduled Notification</b-form-checkbox>
                      </div>
                    </b-form-group>
                  </tab-content>
                </form-wizard>
              </div>
            </div>
          </div>
        </div>
      </b-tab>
      <b-tab title="Indonesia">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <b-form-group class="col-6">
                    <label for="input-3">Article Header</label>
                    <b-form-textarea id="input-3" v-model="form.translation.indonesia.header"
                      placeholder="[Eg: EMERALD study: A brief report of kidney and inflammatory outcomes]" rows="3">
                    </b-form-textarea>
                  </b-form-group>
                  <b-form-group label="Article Link" label-for="input-6" class="col-6">
                    <b-form-textarea id="input-6" v-model="form.translation.indonesia.link"
                      placeholder="[Eg: https://www.annfammed.org/content/early/2020/12/15/afm.2591]" rows="3">
                    </b-form-textarea>
                  </b-form-group>
                </div>
                <div class="row">
                  <b-form-group class="col-6">
                    <div class="d-flex justify-content-between">
                           <label for="input-2">Small Description of the Article </label>
                    </div>
                    <b-form-textarea id="input-2" v-model="form.translation.indonesia.small_description"
                      placeholder="Enter Small Description" rows="5" :maxlength="150"></b-form-textarea>
                      <div class="text-right">
                        <p v-if="form.translation.indonesia.small_description.length" class="badge position-absolute" style="top: 4px; right: 13px" :class="{
                          'badge-success': form.translation.indonesia.small_description.length !== 800,
                          'badge-danger': form.translation.indonesia.small_description.length === 800
                        }">
                          You typed
                          {{ form.translation.indonesia.small_description.length }} out of 800 chars.
                        </p>
                      </div>
                  </b-form-group>
                  <b-form-group label-for="input-5" class="col-6">
                    <div class="d-flex justify-content-between">
                      <label for="input-5">Journal </label>
                      <!-- <label for="">Count : {{ form.translation.indonesia.journal.length }} Characters</label> -->
                      <div class="text-right">
                        <p v-if="form.translation.indonesia.journal.length" class="badge position-absolute" style="top: 4px; right: 13px" :class="{
                          'badge-success': form.translation.indonesia.journal.length !== 800,
                          'badge-danger': form.translation.indonesia.journal.length === 800
                        }">
                          You typed
                          {{ form.translation.indonesia.journal.length }} out of 800 chars.
                        </p>
                      </div>
                    </div>
                    <b-form-textarea id="input-5" v-model="form.translation.indonesia.journal"
                      placeholder="[Eg: European Journal of Heart Failure]" rows="5" :maxlength="150"></b-form-textarea>
                  </b-form-group>
                </div>
                <div class="row">
                  <b-form-group class="col-6" label="Button Text" label-for="input-9">
                    <b-form-input id="input-9" v-model="form.translation.indonesia.button_text"
                      placeholder="Enter Button Text"></b-form-input>
                  </b-form-group>
                  <b-form-group class="col-6">
                    <label for="image">Thumbnail Image</label>
                    <b-form-file id="image" accept="image/*" placeholder="Choose a file or drop it here..."
                      @change="readFile($event, 'image_name_indonesia')" ref="image_name_indonesia"></b-form-file>
                    <template v-if="$route.name == 'edit-article' && edit.image_name_indonesia_url">
                      <img :src="edit.image_name_indonesia_url" width="128px" height="128px"
                        style="object-fit: contain; margin-top: 5px" />
                    </template>
                    <template v-if="image_name_indonesia_url">
                      <img :src="image_name_indonesia_url" width="128px" height="128px" ref="image_name_indonesia_url"
                        style="object-fit: contain; margin-top: 5px" />
                    </template>
                  </b-form-group>
                </div>
                <div class="row">
                  <b-form-group class="col-6" label="Meta Title" label-for="input-9">
                    <b-form-input id="input-9" v-model="form.translation.indonesia.meta_title"
                      placeholder="Enter Meta Title"></b-form-input>
                  </b-form-group>
                  <b-form-group class="col-6" label="Meta Keywords" label-for="input-11">
                    <b-form-input id="input-11" v-model="form.translation.indonesia.meta_keywords"
                      placeholder="Enter Meta Keywords"></b-form-input>
                  </b-form-group>
                </div>
                <b-form-group label="Meta Description" label-for="input-10">
                  <b-form-textarea id="input-10" v-model="form.translation.indonesia.meta_desc"
                    placeholder="Enter Meta Description" rows="3">
                  </b-form-textarea>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import MixinRequest from "../../../mixins/request";
import articleMixin from "../../../mixins/ModuleJs/article";
import { required } from "vuelidate/lib/validators";
import { FormWizard, TabContent } from "vue-form-wizard";
import Multiselect from "vue-multiselect";
import moment from "moment";

export default {
  mixins: [MixinRequest, articleMixin],
  data() {
    return {
      isSummarize: false,
      mycolor: '#' + (Math.random() * 0xFFFFFF << 0).toString(16),
      submitted: false,
      submitted2: false,
      title1: "Add Article",
      title2: "Edit Article",
      items: [
        {
          text: "Back",
          href: "/article",
        },
        {
          text: "Data",
        },
      ],
    };
  },
  components: {
    Layout,
    FormWizard,
    TabContent,
    Multiselect
  },
  validations: {
    form: {
      community_selected: { required },
      small_description: { required },
      header: { required },
      article_timestamp: { required },
      title: { required }
    },
    card_image: { required }
  },
  methods: {
    randdomColor() {
      this.mycolor = '#' + (Math.random() * 0xFFFFFF << 0).toString(16);
      document.querySelector('.thumbnail-card').style.background = this.mycolor;
    },
    validateFormOne() {
      this.submitted = true;
      if (this.$v.form.title.$invalid ||this.$v.form.header.$invalid || this.$v.form.small_description.$invalid || this.$v.card_image.$invalid) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Please Fill The Required Fields"
        });
        return false;
      }
      return true;
    },
    validateFormTwo() {
      this.submitted2 = true;
      if (this.$v.form.article_timestamp.$invalid || this.$v.form.community_selected.$invalid) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Please Fill The Required Fields"
        });
        return false;
      }
      return true;
    },
    disabledDates() {
      return new Date().toISOString().slice(0, 16);
    },
  },
  mounted() {
    document.querySelector('.thumbnail-card').style.background = this.mycolor;
  },
  filters: {
    moment: function (article_timestamp) {
      return moment(article_timestamp).format('Do MMMM');
    }
  }
};
</script>

<style>
.tooltip-inner{
  width: 155px !important
}
</style>